import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <h3>AI-Generated Website</h3>
        <p>
          Everything related to openai.ai is generated by AI, including the website and its content. The code for the website was generated using ChatGPT, an advanced AI language model developed by OpenAI.
        </p>
      </div>
      <div className="footer-copyright">
        &copy; {new Date().getFullYear()} openai.ai. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;